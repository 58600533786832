/**
 *  Name : Nevil Kristi
 *  Date : 16-12-2021
 */
/** IceBreaker list */

export const CREATE_GAME = "CREATE_GAME";
export const CREATE_GAME_SUCCESS = "CREATE_GAME_SUCCESS";
export const CREATE_GAME_ERROR = "CREATE_GAME_ERROR";

export const FETCH_MY_GAME = "FETCH_MY_GAME";
export const FETCH_MY_GAME_SUCCESS = "FETCH_MY_GAME_SUCCESS";
export const FETCH_MY_GAME_ERROR = "FETCH_MY_GAME_ERROR";

export const FETCH_SINGLE_GAME = "FETCH_SINGLE_GAME";
export const FETCH_SINGLE_GAME_SUCCESS = "FETCH_SINGLE_GAME_SUCCESS";
export const FETCH_SINGLE_GAME_ERROR = "FETCH_SINGLE_GAME_ERROR";

export const FETCH_FILTERED_GAME = "FETCH_FILTERED_GAME";
export const FETCH_FILTERED_GAME_SUCCESS = "FETCH_FILTERED_GAME_SUCCESS";
export const FETCH_FILTERED_GAME_ERROR = "FETCH_FILTERED_GAME_ERROR";

export const DELETE_USER_GAME = "DELETE_USER_GAME";
export const DELETE_USER_GAME_SUCCESS = "DELETE_USER_GAME_SUCCESS";
export const DELETE_USER_GAME_ERROR = "DELETE_USER_GAME_ERROR";

export const FETCH_PLAYED_GAME = "FETCH_PLAYED_GAME";
export const FETCH_PLAYED_GAME_SUCCESS = "FETCH_PLAYED_GAME_SUCCESS";
export const FETCH_PLAYED_GAME_ERROR = "FETCH_PLAYED_GAME_ERROR";

export const ADD_UPDATE_PLAYED_GAME = "ADD_UPDATE_PLAYED_GAME";
export const ADD_UPDATE_PLAYED_GAME_SUCCESS = "ADD_UPDATE_PLAYED_GAME_SUCCESS";
export const ADD_UPDATE_PLAYED_GAME_ERROR = "ADD_UPDATE_PLAYED_GAME_ERROR";

export const FETCH_FAVORITE_GAME = "FETCH_FAVORITE_GAME";
export const FETCH_FAVORITE_GAME_SUCCESS = "FETCH_FAVORITE_GAME_SUCCESS";
export const FETCH_FAVORITE_GAME_ERROR = "FETCH_FAVORITE_GAME_ERROR";

export const ADD_UPDATE_FAVORITE_GAME = "ADD_UPDATE_FAVORITE_GAME";
export const ADD_UPDATE_FAVORITE_GAME_SUCCESS =
  "ADD_UPDATE_FAVORITE_GAME_SUCCESS";
export const ADD_UPDATE_FAVORITE_GAME_ERROR = "ADD_UPDATE_FAVORITE_GAME_ERROR";

export const DELETE_GAME_ATTACHMENT = "DELETE_GAME_ATTACHMENT";
export const DELETE_GAME_ATTACHMENT_SUCCESS = "DELETE_GAME_ATTACHMENT_SUCCESS";
export const DELETE_GAME_ATTACHMENT_ERROR = "DELETE_GAME_ATTACHMENT_ERROR";

export const SET_GALLERY_SHOW = "SET_GALLERY_SHOW";
export const REMOVE_PLAYED_GAME = "REMOVE_PLAYED_GAME";

export const VIEW_GAME = "VIEW_GAME";
export const VIEW_GAME_SUCCESS = "VIEW_GAME_SUCCESS";
export const VIEW_GAME_ERROR = "VIEW_GAME_ERROR";

export const REMOVE_FAVORITE_GAME = "REMOVE_FAVORITE_GAME";

export const SET_PREVIOUS_URL = "SET_PREVIOUS_URL";

export const GET_FEATURED_CARD = "GET_FEATURED_CARD";
export const GET_FEATURED_CARD_SUCCESS = "GET_FEATURED_CARD_SUCCESS";
export const GET_FEATURED_CARD_ERROR = "GET_FEATURED_CARD_ERROR";

export const REPORT_ATTACHMENT = "REPORT_ATTACHMENT";
export const REPORT_ATTACHMENT_SUCCESS = "REPORT_ATTACHMENT_SUCCESS";
export const REPORT_ATTACHMENT_ERROR = "REPORT_ATTACHMENT_ERROR";

export const FETCH_GAME_LINK = "FETCH_GAME_LINK";
export const FETCH_GAME_LINK_SUCCESS = "FETCH_GAME_LINK_SUCCESS";
export const FETCH_GAME_LINK_ERROR = "FETCH_GAME_LINK_ERROR";
