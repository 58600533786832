export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const SET_ERROR = "SET_ERROR";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SET_AUTH_DATA = "SET_AUTH_DATA";
export const LOGOUT = "LOGOUT";
export const SET_LOADING = "SET_LOADING";
export const SET_TOKEN = "SET_TOKEN";

export const SET_TOKEN_LOADING = "SET_TOKEN_LOADING";
export const SET_TOKEN_ERROR = "SET_TOKEN_ERROR";
export const SET_PROFILE = "SET_PROFILE";

export const SET_IS_AUTH = "SET_IS_AUTH";
