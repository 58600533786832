/**
 *  Name : Nevil Kristi
 *  Date : 16-12-2021
 */
/** DASHBOARD list */

export const SET_LOADING = "SET_LOADING";
export const FETCH_FILTER = "FETCH_FILTER";
export const FETCH_FILTER_SUCCESS = "FETCH_FILTER_SUCCESS";
export const FETCH_FILTER_ERROR = "FETCH_FILTER_ERROR";

export const SET_FILTER_BY = "SET_FILTER_BY";
export const REMOVE_FILTER_BY = "REMOVE_FILTER_BY";
export const REMOVE_ALL_FILTER_BY = "REMOVE_ALL_FILTER_BY";

export const SET_GAME_SORTBY = "SET_GAME_SORTBY";

export const SET_GAME_FILTER = "SET_GAME_FILTER";
export const REMOVE_GAME_FILTER = "REMOVE_GAME_FILTER";
export const REMOVE_ALL_GAME_FILTER = "REMOVE_ALL_GAME_FILTER";

export const LIST_ASSIGNED_FILTER = "LIST_ASSIGNED_FILTER";
export const LIST_ASSIGNED_FILTER_SUCCESS = "LIST_ASSIGNED_FILTER_SUCCESS";
export const LIST_ASSIGNED_FILTER_ERROR = "LIST_ASSIGNED_FILTER_ERROR";

export const EDIT_ASSIGN_FILTER = "EDIT_ASSIGN_FILTER";
export const EDIT_ASSIGN_FILTER_SUCCESS = "EDIT_ASSIGN_FILTER_SUCCESS";
export const EDIT_ASSIGN_FILTER_ERROR = "EDIT_ASSIGN_FILTER_ERROR";

export const ADD_EDIT_FAVORITE = "ADD_EDIT_FAVORITE";
export const ADD_EDIT_FAVORITE_SUCCESS = "ADD_EDIT_FAVORITE_SUCCESS";
export const ADD_EDIT_FAVORITE_ERROR = "ADD_EDIT_FAVORITE_ERROR";

export const SET_ICEBREAKER_FAVORITE_FILTER = "SET_ICEBREAKER_FAVORITE_FILTER";
export const REMOVE_ICEBREAKER_FAVORITE_FILTER =
  "REMOVE_ICEBREAKER_FAVORITE_FILTER";

export const SET_SEARCH_FIELD = "SET_SEARCH_FIELD";
export const SET_HAS_MORE = "SET_HAS_MORE";
