export const FETCH_SINGLE_RATING = "FETCH_SINGLE_RATING";
export const FETCH_SINGLE_RATING_SUCCESS = "FETCH_SINGLE_RATING_SUCCESS";
export const FETCH_SINGLE_RATING_ERROR = "FETCH_SINGLE_RATING_ERROR";

export const FETCH_GAME_RATING = "FETCH_GAME_RATING";
export const FETCH_GAME_RATING_SUCCESS = "FETCH_GAME_RATING_SUCCESS";
export const FETCH_GAME_RATING_ERROR = "FETCH_GAME_RATING_ERROR";

export const ADD_UPDATE_RATING = "ADD_UPDATE_RATING";
export const ADD_UPDATE_RATING_SUCCESS = "ADD_UPDATE_RATING_SUCCESS";
export const ADD_UPDATE_RATING_ERROR = "ADD_UPDATE_RATING_ERROR";
export const RESET_RATING_DATA = "RESET_RATING_DATA";

export const UPDATE_ATTACHMENT = "UPDATE_ATTACHMENT";
