export const SET_TOKEN_ERROR = "SET_TOKEN_ERROR";

export const SET_IS_AUTH = "SET_IS_AUTH";

export const SET_PAGE_DETAILS = "SET_PAGE_DETAILS";
export const REMOVE_PAGE_DETAILS = "REMOVE_PAGE_DETAILS";

export const GET_PAGE_DETAILS = "GET_PAGE_DETAILS";
export const GET_PAGE_DETAILS_SUCCESS = "GET_PAGE_DETAILS_SUCCESS";
export const GET_PAGE_DETAILS_ERROR = "GET_PAGE_DETAILS_ERROR";

export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_ERROR = "GET_NOTIFICATION_ERROR";

export const SEEN_NOTIFICATION = "SEEN_NOTIFICATION";
export const SEEN_NOTIFICATION_SUCCESS = "SEEN_NOTIFICATION_SUCCESS";
export const SEEN_NOTIFICATION_ERROR = "SEEN_NOTIFICATION_ERROR";

export const SEEN_ALL_NOTIFICATION = "SEEN_ALL_NOTIFICATION";
export const SEEN_ALL_NOTIFICATION_SUCCESS = "SEEN_ALL_NOTIFICATION_SUCCESS";
export const SEEN_ALL_NOTIFICATION_ERROR = "SEEN_ALL_NOTIFICATION_ERROR";

export const GET_ALL_TUTORIAL = "GET_ALL_TUTORIAL";
export const GET_ALL_TUTORIAL_SUCCESS = "GET_ALL_TUTORIAL_SUCCESS";
export const GET_ALL_TUTORIAL_ERROR = "GET_ALL_TUTORIAL_ERROR";

export const GET_ALL_FAQ = "GET_ALL_FAQ";
export const GET_ALL_FAQ_SUCCESS = "GET_ALL_FAQ_SUCCESS";
export const GET_ALL_FAQ_ERROR = "GET_ALL_FAQ_ERROR";

export const GET_TUTORIAL_DETAIL = "GET_TUTORIAL_DETAIL";
export const GET_TUTORIAL_DETAIL_SUCCESS = "GET_TUTORIAL_DETAIL_SUCCESS";
export const GET_TUTORIAL_DETAIL_ERROR = "GET_TUTORIAL_DETAIL_ERROR";

export const SET_TUTORIAL_DATA = "SET_TUTORIAL_DATA";

export const GET_SYSTEM_CONFIGURATION = "GET_SYSTEM_CONFIGURATION";
export const GET_SYSTEM_CONFIGURATION_SUCCESS =
  "GET_SYSTEM_CONFIGURATION_SUCCESS";
export const GET_SYSTEM_CONFIGURATION_ERROR = "GET_SYSTEM_CONFIGURATION_ERROR";

export const GET_PROFILE_SIDEBAR_MENU = "GET_PROFILE_SIDEBAR_MENU";
export const SET_PROFILE_SIDEBAR_LOADING = "SET_PROFILE_SIDEBAR_LOADING";

export const SET_USER_DEVICE_TOKEN = "SET_USER_DEVICE_TOKEN";

export const RESET = "RESET";
