export const FETCH_NOTE = "FETCH_NOTE";
export const FETCH_NOTE_SUCCESS = "FETCH_NOTE_SUCCESS";
export const FETCH_NOTE_ERROR = "FETCH_NOTE_ERROR";

export const ADD_UPDATE_NOTE = "ADD_UPDATE_NOTE";
export const ADD_UPDATE_NOTE_SUCCESS = "ADD_UPDATE_NOTE_SUCCESS";
export const ADD_UPDATE_NOTE_ERROR = "ADD_UPDATE_NOTE_ERROR";

export const DELETE_NOTE = "DELETE_NOTE";
export const DELETE_NOTE_SUCCESS = "DELETE_NOTE_SUCCESS";
export const DELETE_NOTE_ERROR = "DELETE_NOTE_ERROR";
