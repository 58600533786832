/**
 *  Name : Nevil Kristi
 *  Date : 16-12-2021
 */
/** IceBreaker list */

export const CREATE_ICEBREAKER = "CREATE_ICEBREAKER";
export const CREATE_ICEBREAKER_SUCCESS = "CREATE_ICEBREAKER_SUCCESS";
export const CREATE_ICEBREAKER_ERROR = "CREATE_ICEBREAKER_ERROR";

export const DELETE_ICEBREAKER = "DELETE_ICEBREAKER";
export const DELETE_ICEBREAKER_SUCCESS = "DELETE_ICEBREAKER_SUCCESS";
export const DELETE_ICEBREAKER_ERROR = "DELETE_ICEBREAKER_ERROR";

export const FETCH_ICEBREAKER = "FETCH_ICEBREAKER";
export const FETCH_ICEBREAKER_SUCCESS = "FETCH_ICEBREAKER_SUCCESS";
export const FETCH_ICEBREAKER_ERROR = "FETCH_ICEBREAKER_ERROR";

export const FETCH_ICEBREAKER_LIST = "FETCH_ICEBREAKER_LIST";
export const FETCH_ICEBREAKER_LIST_SUCCESS = "FETCH_ICEBREAKER_LIST_SUCCESS";
export const FETCH_ICEBREAKER_LIST_ERROR = "FETCH_ICEBREAKER_LIST_ERROR";

export const FETCH_SINGLE_ICEBREAKER = "FETCH_SINGLE_ICEBREAKER";
export const FETCH_SINGLE_ICEBREAKER_SUCCESS =
  "FETCH_SINGLE_ICEBREAKER_SUCCESS";
export const FETCH_SINGLE_ICEBREAKER_ERROR = "FETCH_SINGLE_ICEBREAKER_ERROR";

export const FETCH_FAVORITE_ICEBREAKER = "FETCH_FAVORITE_ICEBREAKER";
export const FETCH_FAVORITE_ICEBREAKER_SUCCESS =
  "FETCH_FAVORITE_ICEBREAKER_SUCCESS";
export const FETCH_FAVORITE_ICEBREAKER_ERROR =
  "FETCH_FAVORITE_ICEBREAKER_ERROR";

export const SAVE_FAVORITE_ICEBREAKER = "SAVE_FAVORITE_ICEBREAKER";
export const SAVE_FAVORITE_ICEBREAKER_SUCCESS =
  "SAVE_FAVORITE_ICEBREAKER_SUCCESS";
export const SAVE_FAVORITE_ICEBREAKER_ERROR = "SAVE_FAVORITE_ICEBREAKER_ERROR";

export const REMOVE_FAVORITE_ICEBREAKER = "REMOVE_FAVORITE_ICEBREAKER";
