/**
 *  Name : Nevil Kristi
 *  Date : 17-12-2021
 */
/** FILTER list */

export const CREATE_FILTER = "CREATE_FILTER";
export const CREATE_FILTER_SUCCESS = "CREATE_FILTER_SUCCESS";
export const CREATE_FILTER_ERROR = "CREATE_FILTER_ERROR";

export const DELETE_FILTER = "DELETE_FILTER";
export const DELETE_FILTER_SUCCESS = "DELETE_FILTER_SUCCESS";
export const DELETE_FILTER_ERROR = "DELETE_FILTER_ERROR";
